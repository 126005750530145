import { isEmpty, get } from 'utils/lodashExtracts'
import { siteUrl } from 'utils/siteConfig'
import Cookies from 'universal-cookie';


const PAGE_CATEGORY_META_NAME = 'charles:page_category'
const CATEGORIES = {
  HOME: 'Home',
  START: 'Consulter',
  PATHOLOGIES: 'Pathologies',
  THERAPIES: 'Thérapies',
  BLOG: 'Blog',
  BLOG_POST: 'Article de blog',
  ANNUARY: 'Annuaire',
  DEFAULT: 'Autre'
}
const EVENTS = {
  CONSULTATION_CATEGORY_CHOSEN: 'consultation_category_chosen',
  CONSULTATION_SPECIALITY_CHOSEN: 'consultation_speciality_chosen',
  VIDEO_PLAYED: 'video_played',
  MENU_OPENED: 'menu_opened',
  MENU_LINK_CLICKED: 'menu_link_clicked',
  ANSWER_DISPLAYED: 'answer_displayed',
}

const onRouteUpdate = ({ location, prevLocation }) => {

  const trackSegmentPage = () => {
    // Adding a 50ms delay
    // ensure that the segment route tracking is in sync with the actual Gatsby route
    // (otherwise you can end up in a state where the Segment page tracking reports
    // the previous page on route change).
    const delay = 50

    const properties = {}
    const cookies = new Cookies()
    cookies.get('_fbp') && (properties.fbp = cookies.get('_fbp'))
    cookies.get('_fbc') && (properties.fbc = cookies.get('_fbc'))
    window.setTimeout(() => {
      // window.analytics && window.analytics.page(location.pathname)
      const metaCategory = get(document.querySelector(`meta[name="${PAGE_CATEGORY_META_NAME}"]`), 'content')
      const category = isEmpty(metaCategory) ? CATEGORIES.DEFAULT : metaCategory
      window.analytics && window.analytics.page(category, document.title, properties)
    }, delay)
  }

  trackSegmentPage()

  // When we will use https://github.com/segmentio/consent-manager for cookies management
  // --- extracted from gatsby-plugin-segment-js code :
  // // This `if/then` logic relates to the `delayLoad` functionality to help prevent
  // // calling `trackPage` twice. If you don't use that feature, you can ignore.
  // // Here call `trackPage` only _after_ we change routes (on the client).
  // if (prevLocation && window.segmentSnippetLoaded === false) {
  //   window.segmentSnippetLoader(() => {
  //     trackSegmentPage()
  //   });
  // } else {
  //   trackSegmentPage()
  // }
}

export const trackProductViewed = (product_id) => {
  window.analytics && window.analytics.track('Product Viewed', {
    product_id: product_id,
  })
}

const track = (name, options) => {
  options ||= {}
  options.platform = "charles"
  const cookies = new Cookies()
  cookies.get('_fbp') && (options.fbp = cookies.get('_fbp'))
  cookies.get('_fbc') && (options.fbc = cookies.get('_fbc'))
  window.analytics && window.analytics.track(name, options)
}

export const trackCategoryChosen = (category) => {
  track(EVENTS.CONSULTATION_CATEGORY_CHOSEN, {
    category
  })
}
export const trackSpecialtyChosen = (category, specialty) => {
  track(EVENTS.CONSULTATION_SPECIALITY_CHOSEN, {
    category,
    specialty
  })
}

export const trackVideoPlayed = (videoName, videoUrl) => {
  track(EVENTS.VIDEO_PLAYED, {
    videoName,
    videoUrl,
    originUrl: window.location.href
  })
}

export const trackMenuOpened = () => {
  track(EVENTS.MENU_OPENED, {})
}

export const trackMenuLinkClicked = (link, anchor) => {
  track(EVENTS.MENU_LINK_CLICKED, {
    link: siteUrl + link,
    anchor
  })
}

export const trackAccordionDeployed = (question_title) => {
  track(EVENTS.ANSWER_DISPLAYED, {
    question_title
  })
}

export default {
  PAGE_CATEGORY_META_NAME,
  CATEGORIES,
  onRouteUpdate
}
